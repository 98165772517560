import { Providers, ProviderState } from '@microsoft/mgt-element';
import { Login } from '@microsoft/mgt-react';
import React, { useState, useEffect } from 'react';
import 'devextreme/dist/css/dx.light.css';
import './App.css';
import Licenses from './components/Licenses';

function useIsSignedIn(): [boolean] {

  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {

    const updateState = () => {

      const provider = Providers.globalProvider;

      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);

    };

    Providers.onProviderUpdated(updateState);

    updateState();

    return () => {

      Providers.removeProviderUpdatedListener(updateState);

    }

  }, []);

  return [isSignedIn];

}


function App() {

  const [isSignedIn] = useIsSignedIn();

  return (

    <div className="App">
      <header className="App-header">
        <p>
          Landis Attendant Console Management Portal
        </p>

        <Login />
        <div>
          {isSignedIn &&
            <div>
              <Licenses/>
            </div>
          }
        </div>
      </header>
    </div>

  );

}

export default App;

