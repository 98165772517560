import { Providers } from '@microsoft/mgt-react';
import { DataGrid, DateBox} from 'devextreme-react';
import { Column, Editing, Lookup, SearchPanel, StateStoring } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import React, {  useRef } from 'react';
import { licenseInfo } from '../interfaces/licenseInfo';

export interface LicensesProps {
  startTime?: Date;
}


// eslint-disable-next-line import/no-anonymous-default-export
export default (props: LicensesProps): JSX.Element => {

  const localEditItem = useRef<licenseInfo>({id: '',tenantId:'',isTrial: true, expirationDate: '', qty: 1, notes: '', createdDate: '', name: '', businessName: '', phone: '', email : '', contactType : ''});
  const _contactTypes = ['Customer', 'Partner'];

  const lookupDataSource: any = {
    store: new CustomStore({
        key: "id",
        //loadMode: "raw",
        load: (loadOptions: any): Promise<any[]> => {
         
          let provider = Providers.globalProvider;
          console.log('now trying to get backend token');
          let scope = process.env.REACT_BACKEND_SCOPES ?? '';
          return provider.getAccessToken({ scopes: ['api://d1e38366-6775-4f3f-aeec-f4d055d675ed/.default'] }).then((token) => {
            console.log('got backend token');
           // console.log(token);

            var headers = new Headers();
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
              method: "GET",
              headers: headers
            };
          

            return fetch('/api/GetLicenses', options)
                .then(response => {
                  return response.json()
                  .then(data => {
                    let returnData: licenseInfo[] = data
                   return returnData;

                  });
                
                }).catch(function (error) {
                  console.error(error);
                  throw 'Network error when getting licenses' })

          })

        },
     
        update: (key, values) => {
                
              let updateDto = Object.assign(localEditItem.current, values);
      
                let provider = Providers.globalProvider;
                let scope = process.env.REACT_BACKEND_SCOPES ?? '';
                return provider.getAccessToken({ scopes: ['api://d1e38366-6775-4f3f-aeec-f4d055d675ed/.default'] }).then((token) => {
                  console.log('got backend token');
                  //console.log(token);
      
                  var headers = new Headers();
                  var bearer = "Bearer " + token;
                  headers.append("Authorization", bearer);
                  var options = {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(updateDto)
                  };
                
                  return fetch('/api/UpdateLicense', options)
                      .then(response => {
                         response.json()
                         .then(response => {
                          //handle errors 
                          if (!response.ok)
                          return response;
                            });
      
                        });

        });
      }
    }),
    sort: "name"
  };

  function updateRow(e: any) {
    //work around for getting the full row data
    localEditItem.current = e.oldData;
  }

 

  return (
    <div className="dx-field">

      <DataGrid
        dataSource={lookupDataSource}
        showBorders={true}
        height={600}
        onRowUpdating={updateRow}
        allowColumnResizing={true}
        columnResizingMode={"widget"}
        columnAutoWidth={true}
        allowColumnReordering={true}
        rowAlternationEnabled={false}
        showRowLines={true}
    >
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Editing
          mode="row"
          allowAdding={false}
          allowDeleting={false}
          allowUpdating={true}
        />
        <StateStoring enabled={true} type="localStorage" storageKey="storage" />

        <Column dataField="tenantId" caption="Tenant ID" dataType="string" allowEditing={false}></Column>
        <Column dataField="isTrial" caption="Is Trial" dataType="boolean"></Column>
        <Column dataField="expirationDate" caption="Expiration Date" dataType="date">
          <DateBox  />
        </Column>
        <Column dataField="qty" caption="Qty" dataType="number"></Column>
        <Column dataField="notes" caption="Notes" dataType="string"></Column>
        <Column dataField="name" caption="Name" dataType="string"></Column>
        <Column dataField="businessName" caption="Business Name" dataType="string"></Column>
        <Column dataField="phone" caption="Phone" dataType="string"></Column>
        <Column dataField="email" caption="Email" dataType="string"></Column>
        <Column dataField="contactType" caption="Contact Type" dataType="string">
          <Lookup
            dataSource={_contactTypes}
            />
        </Column>
        <Column dataField="createdDate" caption="Created Date" dataType="date" allowEditing={false}></Column>
  
      </DataGrid>
      {/* </div> */}
    </div>
  );
};
